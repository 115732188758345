import {Component, Provide, Vue,} from 'vue-property-decorator';
import common from '../Common/Index.vue'
import {AddFeedbackReq} from "@/interface/res/mine";
import {AddFeedbackApi} from "@/network/modules/mine";

@Component({
  components: {
    common
  },
  computed: {
  }
})
export default class Opinion extends Vue {
  @Provide() loading:boolean = false
  @Provide() form:AddFeedbackReq = {
    feedbackContent:"",
    feedbackName:"",
    feedbackPhone:""
  }
  @Provide() feedbackContentMsg:string = ''
  @Provide() feedbackNameMsg:string = ''
  @Provide() feedbackPhoneMsg:string = ''
  created(){
    const _this:any = this;
  }

  async AddFeedbackFn(){
    const _this:any = this;

    if(_this.$base.isNull(_this.form.feedbackContent)){
      _this.feedbackContentMsg = "反馈意见不能为空"
      return
    }
    _this.feedbackContentMsg = ""

    if(_this.$base.isNull(_this.form.feedbackName)){
      _this.feedbackNameMsg = "请输入您的称呼"
      return
    }
    _this.feedbackNameMsg = ""

    if(_this.$base.isNull(_this.form.feedbackPhone)){
      _this.feedbackPhoneMsg = "请输入您的联系方式"
      return
    }
    _this.feedbackPhoneMsg = ""
    _this.loading = true
    let params:AddFeedbackReq = {..._this.form}
    const res = await AddFeedbackApi(params)
    let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},500)
    const { code, msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }

    _this.$message.success('谢谢您的反馈！');

    _this.form.feedbackContent =
      _this.form.feedbackName =
        _this.form.feedbackPhone = ""


  }

}
